<template>
  <div class="input_container">
    <el-row class="input_header">
      <el-col :span="24">{{ name }}</el-col>
    </el-row>
    <el-row class="tip">
      <h1>温馨提示：请使用屏幕数字键盘输入门诊号，并点击确认按钮</h1>
    </el-row>
    <el-row class="input_main">
      <div class="ipt">{{ value }}</div>
      <div class="key">
        <el-button style="margin-left:7px" type="primary" @click.native="inputNum(1)">1</el-button>
        <el-button type="primary" @click.native="inputNum(2)">2</el-button>
        <el-button type="primary" @click.native="inputNum(3)">3</el-button>
        <el-button type="primary" @click.native="inputNum(4)">4</el-button>
        <el-button type="primary" @click.native="inputNum(5)">5</el-button>
        <el-button type="primary" @click.native="inputNum(6)">6</el-button>
        <el-button type="primary" @click.native="inputNum(7)">7</el-button>
        <el-button type="primary" @click.native="inputNum(8)">8</el-button>
        <el-button type="primary" @click.native="inputNum(9)">9</el-button>

        <el-button type="primary" @click.native="inputNum('X')">X</el-button>
        <el-button type="primary" @click.native="inputNum(0)">0</el-button>
        <el-button type="primary" @click.native="inputNum('-')">-</el-button>
        <el-button style="background-color: #009944;" type="primary" @click.native="confirm">确认</el-button>
        <el-button style="background-color: #f17e58;" type="primary" @click.native="clear">清空</el-button>
        <el-button style="background-color: #49b9e1;" type="primary" @click.native="del">退格</el-button>
      </div>
    </el-row>
    <el-button
      class="back"
      style="width: 100px;
            height: 50px;"
      type="primary"
      @click.native="back"
      >返回
    </el-button>
    <el-button
      class="home"
      style="width: 100px;
            height: 50px;"
      type="primary"
      @click.native="home"
      >首页
    </el-button>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'

import qs from 'qs'

export default {
  components: {
    Foo
  },
  data() {
    return {
      name: '',
      value: '',
      count: '',
      timer: '',
      disabled: false
    }
  },
  created() {
    this.name = this.$route.query.name
  },

  methods: {
    inputNum(num) {
      if (this.value.length > 17) {
        this.disabled = true
      } else {
        this.disabled = false
        this.value += num
      }
    },
    back() {
      this.$router.back()
    },
    home() {
      this.$router.push('/home')
    },
    del() {
      this.value = this.value.substr(0, this.value.length - 1)
      this.disabled = false
    },
    clear() {
      this.disabled = false
      this.value = ''
    },
    async confirm() {
      if (this.name == '检查报告') {
        this.$router.push({
          path: '/check',
          query: {
            MZH: this.value
          }
        })
      } else {
        this.$router.push({
          path: '/inspectiondetails',
          query: {
            MZH: this.value
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.input_container {
  width: 100%;
  height: 100%;
  //background-image: url('../../assets/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.input_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.tip h1 {
  margin-top: 50px;
  color: #19caad;
  font-weight: 700;
  text-align: center;
  font-size: 50px;
}

.input_main {
  width: 60%;
  margin: 0 auto;
}

.input_main .ipt {
  padding-left: 10px;
  font-size: 60px;
  width: 100%;
  font-weight: 700;
  color: white;
  height: 100px;
  border: 3px solid #000;
  margin-top: 2%;
  line-height: 100px;
}

.input_main .key {
  text-align: center;
  margin-left: 5%;
  width: 90%;
  margin-top: 100px;
}

.input_main .key .el-button {
  margin-top: 25px;
  width: 28%;
  height: 150px;
  font-weight: bold;
  font-size: 60px;
  background-color: #3b77d8;
}

.input_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.back {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 300px;
  font-size: 26px !important;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}
</style>
